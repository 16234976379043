<template>
  <div class="wrap">
    <el-table :data="tableData" style="width: 100%" @row-click="goDetail">
      <el-table-column label="头像">
        <template slot-scope="scope">
          <el-image
            style="width: 50px"
            :src="scope.row.weixin_pic"
            :preview-src-list="[scope.row.weixin_pic]"
          >
          </el-image>
        </template>
      </el-table-column>

      <el-table-column label="姓名">
        <template slot-scope="scope">
          {{ scope.row.weixin_nickname }}
        </template>
      </el-table-column>
      <el-table-column label="电话">
        <template slot-scope="scope">
          {{ scope.row.phone }}
        </template>
      </el-table-column>

      <el-table-column label="是否有大简历">
        <template slot-scope="scope">
          {{ scope.row.resume_id ? "是" : "否" }}
        </template>
      </el-table-column>

      <el-table-column label="咨询职位">
        <template slot-scope="scope">
          {{ scope.row.jd_title ? scope.row.jd_title : "无" }}
        </template>
      </el-table-column>

      <el-table-column label="最后聊天内容">
        <template slot-scope="scope">
          <div
            v-if="scope.row.type == 2 && scope.row.state == 0"
            class="dian"
          ></div>
          {{ scope.row.content }}
        </template>
      </el-table-column>

      <el-table-column label="公司">
        <template slot-scope="scope">
          {{ scope.row.company_name }}
        </template>
      </el-table-column>

      <el-table-column label="时间">
        <template slot-scope="scope">
          {{ scope.row.time }}
        </template>
      </el-table-column>

      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            class="btn"
            size="mini"
            v-on:click.stop="onChatDetail(scope)"
            >聊天</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="pageTotal"
        :page-size="20"
        :current-page="pageNow"
        @current-change="pageNowHandler"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { chat_list_gl } from "@/api/chat/index.js";

import axios from "axios";
export default {
  data() {
    return {
      //总页码
      pageTotal: null,
      pageNow: 1,

      search_str: "",
      search_sel: [
        { label: "用户", value: "username" },
        { label: "公司", value: "license_company_name" },
      ],
      select_index: "",
      // 表单数据处理
      tableData_cell: [
        { title: "姓名", value: "weixin_nickname" },
        { title: "最后聊天内容", value: "content" },
        { title: "时间", value: "time" },
      ],
      tableData: [],
    };
  },
  mounted() {},
  methods: {
    // 进入聊天详情页面
    onChatDetail(item) {
      //获取数据跳转到聊天页面
      console.log(item.row);
      this.$router.push({
        path: "/chatdetail_gl",
        query: {
          user_detail_id: item.row.user_detail_id,
          elite_id: item.row.elite_id,
          jd_id: item.row.jd_id,
        },
      });
    },

    getTime(date) {
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return y + "-" + m + "-" + d;
    },

    //跳转到简历页面
    goDetail(row, column, event) {
      console.log("sssssssss");
      console.log(row);
      if (row.resume_id) {
        this.$router.push({
          path: "/resumedetails",
          query: {
            id: row.resume_id,
          },
        });
      }
    },

    handleClick(val) {
      console.log(val);
    },
    areaChange(e) {
      console.log(e);
    },
    indusChange(e) {
      console.log(e);
    },
    get_list(e) {
      chat_list_gl({ page: this.pageNow }).then((res) => {
        console.log(res);

        if (!res.code) {
          if (res.data.length) {
          }

          this.pageTotal = res.all_count;
          this.tableData = res.data;
          // this.$message.success(res.msg)
        } else {
          this.$message.error(res.msg);
        }
        console.log(res);
      });
    },

    pageNowHandler(page) {
      console.log("pageNowHandler", page);

      if (this.pageNow != page) {
        this.loading = true;

        this.pageNow = page;

        this.get_list();
      }
    },
  },

  created() {
    console.log("created");

    this.pageNow = 1;
    this.get_list();
  },
};
</script>

<style scoped>
.pagination {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}
.wrap {
  padding-top: 54px;
}
.filter {
  margin-bottom: 10px;
}
.wrap_box1 {
  display: flex;
}

.el-input {
  width: 30%;
  margin-right: 10px;
}

.get-btn-wrap {
  width: 100%;
  height: 50px;
}
.get-btn {
  float: left;
}

.get-btn::after {
  content: "";
  display: block;
  visibility: hidden;
  height: 0;
  clear: both;
}
.dian {
  width: 10px;
  height: 10px;
  border-radius: 5px; /* 图形的半径 */

  background-color: red;
  float: left;
  margin-top: 5px;
  margin-right: 5px;
}
</style>
