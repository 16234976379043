import { require } from './../../utils/http'

//聊一聊列表
function chat_list(obj) {
  return require('post', '/api/kapin_ee_admin/auth/v1/chat_list/', obj)
}
//聊一聊 管理
function chat_list_gl(obj) {
  return require('post', '/api/kapin_ee_admin/auth/v1/chat_list_gl/', obj)
}

//聊天详情
function chat_detail(obj) {
  return require('post', '/api/kapin_ee_admin/auth/v1/chat_detail/', obj)
}

//添加聊天
function chat_add(obj) {
  return require('post', '/api/kapin_ee_admin/auth/v1/chat_add/', obj)
}

//聊天详情 管理
function chat_detail_gl(obj) {
  return require('post', '/api/kapin_ee_admin/auth/v1/chat_detail_gl/', obj)
}

//添加聊天 管理
function chat_add_gl(obj) {
  return require('post', '/api/kapin_ee_admin/auth/v1/chat_add_gl/', obj)
}
//  未读条数
function chat_unread(obj) {
  return require('post', '/api/kapin_ee_admin/auth/v1/chat_unread/', obj)
}

export { chat_list, chat_list_gl, chat_detail, chat_add, chat_detail_gl, chat_add_gl, chat_unread }

